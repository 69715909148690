import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';


@Injectable()
export class DomainService {
    domain:string='';

    constructor(
    ) { }
  
    changeDomain(domain: string) {
      this.domain=domain;
    }

    getDomain(){
        return this.domain;
    }
  
  }