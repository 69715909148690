import { Injectable } from "@angular/core";
import { AngularFirestore, Query } from "@angular/fire/firestore";

import { GetUserOrgIdService } from "./getUserOrgId.service";
import { BehaviorSubject } from "rxjs";
import { AngularFireStorage } from "../../../node_modules/@angular/fire/storage";
import {Location} from '@angular/common';
import { Router } from "../../../node_modules/@angular/router";
import * as firebase from 'firebase/app';
import { startOfDay,endOfDay,startOfMonth} from 'date-fns';


@Injectable()
export class MetaDataService {
    child: any;
    foodItem: any;
    events=[];
    employee: any;
  
    constructor(private readonly afs: AngularFirestore,
        private userOrgIdService:GetUserOrgIdService,
        private storage: AngularFireStorage,
        private router: Router,
        private location:Location

         ) { }
        metaDataDoc=null;
        metaDataDocPublic=null;
        employeesArray=[];
        perentsArray=[];
        private perentsArrayValue = new BehaviorSubject([])
        currentperentsArrayValue = this.perentsArrayValue.asObservable();
        areasArray=[];
        calendarTypeArray=[];
        foodArray=[];
        devicesArray=[];
        private devicesValue = new BehaviorSubject(null)
        currentDevicesValue = this.devicesValue.asObservable();
        private areasValue = new BehaviorSubject(null)
        currentAreasValue = this.areasValue.asObservable();
        private calendarTypeValue = new BehaviorSubject(null)
        currentCalendarTypeValue= this.calendarTypeValue.asObservable();
        private listsValue = new BehaviorSubject(null)
        currentListsValue = this.listsValue.asObservable();
        equipmentListsArray=[]
        private equipmentListsValue = new BehaviorSubject(null)
        currentEquipmentListsValue  = this.equipmentListsValue.asObservable();
        private employeesValue = new BehaviorSubject(null)
        currentEmployeesValue = this.employeesValue.asObservable();
        childrenArray=[];
        listsArray=[];
        private childrenValue = new BehaviorSubject(null)
        currentChildrenValue = this.childrenValue.asObservable();
        familyForm:any=null;
        device:any=null;
        typesOfEvents=null;
        messagesArray=[]
        private messagesValue = new BehaviorSubject(null)
        currentMessagesValue = this.messagesValue.asObservable();
        diseasesArray=[];
        private diseasesValue = new BehaviorSubject(null)
        currentDiseasesValue = this.diseasesValue.asObservable();
        onlyAvailableChildrenInDB=true;
        private checkOutValue = new BehaviorSubject({})
        currentcheckOutValue = this.checkOutValue.asObservable();
        reportId: string;
        checkOutObj:any=null;
        private checkInValue = new BehaviorSubject({})
        currentcheckInValue = this.checkInValue.asObservable();
        checkInObj:any=null;
        notComingObj=null
        private notComingValue = new BehaviorSubject({})
        currentNotComingValue = this.notComingValue.asObservable();
        childrenAfterFilter=null
        comingLateObj=null
        private comingLateValue = new BehaviorSubject({})
        currentComingLateValue = this.comingLateValue.asObservable();
        inactiveChildrenArray=[]
        private inactiveChildrenValue = new BehaviorSubject(null)
        currentinActiveChildrenValue = this.inactiveChildrenValue.asObservable();
        frozenChildrenArray=[]
        private frozenChildrenArrayValue = new BehaviorSubject(null)
        currentFrozenChildrenValue = this.frozenChildrenArrayValue.asObservable();
        returnAllWhoInObj: any;
        private returnAllWhoInObjValue = new BehaviorSubject(null)
        whoCurrentReturnAllWhoInObjValue= this.returnAllWhoInObjValue.asObservable();
        locationsArray=[]
        private locationsValue = new BehaviorSubject(null)
        locationsCurrentValue= this.locationsValue.asObservable();
        customReport:any
        employeesInactiveArray=[]
        private employeesInactiveValue = new BehaviorSubject(null)
        currentInactiveEmployeesValue = this.employeesInactiveValue.asObservable();

        resetAll(){

               
            
        this.metaDataDoc=null;
        this.metaDataDocPublic=null;
        this.employeesArray=[];
        this.employeesInactiveArray=[];
        this.perentsArray=[];
        this.areasArray=[];
        this.calendarTypeArray=[];
        this.foodArray=[];
        this.devicesArray=[];
        this.equipmentListsArray=[]
        this.childrenArray=[];
        this.listsArray=[];
        this.familyForm=null;
        this.device=null;
        this.typesOfEvents=null;
        this.messagesArray=[];
        this.diseasesArray=[];
        this.onlyAvailableChildrenInDB=true
        this.reportId=null
        this.checkOutObj=null;
        this.checkInObj=null;
        this.notComingObj=null
        this.childrenAfterFilter=null
        this.comingLateObj=null
        this.inactiveChildrenArray=[]
        this.frozenChildrenArray=[]
        this.returnAllWhoInObj=null;
        this.locationsArray=[]
        this.customReport=null

        }

    
  uploadImages(file:File) {

    var metadata = {
      cacheControl: 'public,max-age=31536000'
    };

    const promise = new Promise<any>(async (resolve, reject) => {
        try {
           var organisationId=await this.userOrgIdService.getUserOrgId()
        }
        catch (err) {
            reject (err)
        }
        var upload = this.storage.ref('organizationFiles/' + organisationId + '/public/' + new Date().getTime().toString()).put(file, metadata);
        upload.then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            resolve(url)

          });
        }).catch((error) => {
          reject(error);
          console.log(error)
        })
    

    });
    return promise;}

    saveContextOnStoarge(fileName:string,path:string,htmlFile:any){
        const promise=  new Promise<any>(async (resolve, reject) => {
            try {
                var organisationId=await this.userOrgIdService.getUserOrgId()
             }
             catch (err) {
                 reject (err)
             }

              this.storage.ref('organizationFiles/' + organisationId + path + fileName).putString(htmlFile).then((snapshot)=>{
                snapshot.ref.getDownloadURL().then((url)=>{
                  resolve (url)})
              }).catch((err)=>{
                  reject(err)
              });
            })
            return promise
       }

       async getAllWhoIn(){
        const promise = new Promise<any>(async (resolve, reject) => {
      
                if (this.returnAllWhoInObj){
                 this.returnAllWhoInObjValue.next(this.returnAllWhoInObj);
                  resolve(this.returnAllWhoInObj)
                  return
                }
                
                try {
                  var orgId=await this.userOrgIdService.getUserOrgId()
                }
                catch (err){
                  reject(err)
                }
                this.afs.doc("/dayCareData/"+orgId+"/employeeCheckIn/"+startOfDay(new Date()).getTime().toString()).ref.onSnapshot
                      ((doc) => {
                          if (doc.exists){
                             this.returnAllWhoInObjValue.next(doc.data());
                              this.returnAllWhoInObj=doc.data()
                              resolve (this.returnAllWhoInObj)
                            }
                            resolve({})
                        })
                    ,err  => {
                      console.log(err);
                      reject(err)
                    }
    
               
              });
              return promise
      
      }

    getMetaData() {
       
        const promise=   new Promise<any>((resolve, reject) => {
            (this.metaDataDoc!=null) ? resolve(this.metaDataDoc) : null
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
            this.afs.doc("dayCareData/"+userOrgId+"/settings/metaDataFile").ref.onSnapshot
            ((doc) => {
                        if (doc.exists) {
                            this.metaDataDoc=doc.data()
                            resolve(this.metaDataDoc)
                        }
                        else {
                            resolve(null)
                        }   
                
                            
                    }
                    ,err  => {
                        reject(err);
                    })
            }).catch((error)=>{reject(error);});
    })
    return promise;}

    getLocations() {
       
        const promise=   new Promise<any>((resolve, reject) => {
            (this.locationsArray.length>0) ? resolve(this.locationsArray) : null
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
            this.afs.collection("dayCareData/"+userOrgId+"/locations").ref.onSnapshot
                ((snapshot) => {
                
                    this.locationsArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.locationsArray.push(doc.data());
                    });
                    this.locationsValue.next(this.locationsArray);
                    resolve(this.locationsArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
               
            }).catch((error)=>{reject(error);});
    })
    return promise;}

    updateMetaData(metaDataDoc:any){

            return this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
               return this.afs.doc("dayCareData/"+userOrgId+"/settings/metaDataFile").set(metaDataDoc)
            })
    

    }

    checkFilePdf(file:File){
        const fileReader = new FileReader();
       
        const promise=  new Promise<any>((resolve, reject) => {
          fileReader.addEventListener("loadend", () => {
            const arr = new Uint8Array(<ArrayBuffer>fileReader.result).subarray(0, 4);
            let header = "";
            let isValid = false;
            for (let i = 0; i < arr.length; i++) {
              header += arr[i].toString(16);
            }
            switch (header) {
              case "25504446": //pdf
                isValid = true;
                break;
              default:
                isValid = false; // Or you can use the blob.type as fallback
                break;
            }
            if (isValid) {
              resolve();
            } else {
              reject("invalidMimeType")
            }
          });
          fileReader.readAsArrayBuffer(file);
        });
      return promise; 
      
      }

    smsTriggerResendAppLink(data:any){

        return this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
           return this.afs.doc("dayCareData/"+userOrgId+"/smsTrigger/"+this.createId()).set(data)
        })


}


    getPublicMetaData() {
       
        const promise=   new Promise<any>((resolve, reject) => {
            if (this.metaDataDocPublic!=null){
                resolve(this.metaDataDocPublic)
                return
            } 
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
            this.afs.doc("dayCareData/"+userOrgId+"/settings/publicMetaData").ref.onSnapshot
            ((doc) => {
                        if (doc.exists) {
                            this.metaDataDocPublic=doc.data()
                            resolve(this.metaDataDocPublic)
                        }
                        else {
                            reject("Doc not exists")
                        }   
                
                            
                    }
                    ,err  => {
                        reject(err);
                    })
            }).catch((error)=>{reject(error);});
    })
    return promise;}

    async addToPublicMetaData(obj:any){
        try {
            var orgId=await   this.userOrgIdService.getUserOrgId()
           }
           catch (err){
            console.log(err)
            return
           }
        return this.afs.doc("dayCareData/"+orgId+"/settings/publicMetaData").set(obj,{merge:true})
    }


    getAllTypesOfEvents(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.typesOfEvents ? resolve (this.typesOfEvents):null
                    this.afs.doc("dayCareData/" + userOrgId +"/settings/categoriesOfEvent").ref.get().then((doc) => {
                    if (doc.exists){
                        this.typesOfEvents=doc.data().items
                        resolve(this.typesOfEvents);

                    }
                    else{
                        reject("Not exists")
                    }
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }
     setCategoryOfEvent(categoryArray:any){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                 return   this.afs.doc("dayCareData/" + userOrgId +"/settings/categoriesOfEvent").set({items:categoryArray},{merge:true})
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     async setTabletLoginId(obj:any){
        try {
            var orgId=await   this.userOrgIdService.getUserOrgId()
            obj['orgId']=orgId
            return   this.afs.doc("tabletsData/" + obj.id).set(obj,{merge:true})
           }
           catch (err){
            console.log(err)
            return
           }
 
        
     }

     async setOneTimeUserLoginIdLoginId(obj:any){
        try {
            var orgId=await   this.userOrgIdService.getUserOrgId()
            obj['orgId']=orgId
            return   this.afs.doc("oneTimeUserLoginId/" + obj.id).set(obj,{merge:true})
           }
           catch (err){
            console.log(err)
            return
           }
 
        
     }

    getAllEmployees(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.employeesArray.length>0 ? resolve (this.employeesArray):null
                    this.afs.collection("dayCareData/" + userOrgId +"/employees").ref.where("status","==",true).onSnapshot
                    ((snapshot) => {
                
                    this.employeesArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.employeesArray.push(doc.data());
                    });
                    this.employeesValue.next(this.employeesArray);
                    resolve(this.employeesArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     getAllInactiveEmployees(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.employeesInactiveArray.length>0 ? resolve (this.employeesInactiveArray):null
                    this.afs.collection("dayCareData/" + userOrgId +"/employees").ref.where("status","==",false).onSnapshot
                    ((snapshot) => {
                
                    this.employeesInactiveArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.employeesInactiveArray.push(doc.data());
                    });
                    this.employeesInactiveValue.next(this.employeesInactiveArray);
                    resolve(this.employeesInactiveArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     getAllPerents(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                   // console.log(this.perentsArray.length)
                    if (this.perentsArray.length>0) {
                        resolve (this.perentsArray)
                        return
                    } 
                    this.afs.collection("dayCareData/" + userOrgId +"/family").ref.onSnapshot
                    ((snapshot) => {
                
                    this.perentsArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.perentsArray=[...this.perentsArray,doc.data()];
                    });
                    this.perentsArrayValue.next(this.perentsArray)
                    resolve(this.perentsArray)
                    
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     getAreaById(id:string){
        const promise=   new Promise<any>((resolve, reject) => {
            this.getAllAreas().then((areas)=>{
                resolve(areas.filter(s => s.areaId==id)[0]);
            }).catch((err)=> reject (err))
        })
        return promise
     }

     updateArea(id:string,area:any){
  
        const promise=   new Promise<any>((resolve, reject) => {
        this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {return userOrgId})
        .then ((organisationId)=>{
          this.afs.doc("dayCareData/"+organisationId+"/areas/"+id).set(area,{merge:true})
          .then(() => {
            resolve()
          })
          .catch(error =>{
           reject(error)
          });
        }).catch((err)=>{console.log(err)})
      
    })
        return promise
    }

    setFood(id:string,object:any){
        const promise=   new Promise<any>((resolve, reject) => {
        this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {return userOrgId})
        .then ((organisationId)=>{
          this.afs.doc("dayCareData/"+organisationId+"/food/"+id).set(object)
          .then(() => {
            resolve()
          })
          .catch(error =>{
           reject(error)
          });
        }).catch((err)=>{console.log(err)})
      
    })
        return promise
    }

    setEvent(object:any){
        const promise=   new Promise<any>((resolve, reject) => {
        this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {return userOrgId})
        .then ((organisationId)=>{
          this.afs.doc("dayCareData/"+organisationId+"/events/"+object.id).set(object)
          .then(() => {
            resolve()
          })
          .catch(error =>{
           reject(error)
          });
        }).catch((err)=>{console.log(err)})
      
    })
        return promise
    }

    getAllEvents(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.events.length>0 ? resolve (this.events):null
                    this.afs.collection("dayCareData/" + userOrgId +"/events").ref.onSnapshot
                    ((snapshot) => {
                
                    this.events.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.events.push(doc.data());
                    });
                    resolve(this.events);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

    getAllCheckIn(start:number,end:number){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {return userOrgId})
            .then ((organisationId)=>{
              this.afs.collection("dayCareData/"+organisationId+"/checkIn/").ref.where("id",">=",start).where("id","<=",end).get().then
              ((snapshot) => {
                    resolve(snapshot)
                })
            }).catch((err)=>{
                reject((err))
                console.log(err)})
            
            })
            return promise 

    }

    getAllCheckOut(start:number,end:number){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {return userOrgId})
            .then ((organisationId)=>{
              this.afs.collection("dayCareData/"+organisationId+"/checkOut/").ref.where("id",">=",start).where("id","<=",end).get().then
              ((snapshot) => {
                    resolve(snapshot)
                })
            }).catch((err)=>{
                reject((err))
                console.log(err)})
            
            })
            return promise 

    }

    setID(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {return userOrgId})
            .then ((organisationId)=>{
              this.afs.collection("dayCareData/"+organisationId+"/checkOut/").ref.get().then
              ((snapshot) => {
                    snapshot.docs.forEach(element => {
                        console.log(element.id)
                        this.afs.doc("dayCareData/"+organisationId+"/checkOut/"+element.id).set({id:Number(element.id)},{merge:true})
                    });
                })
            }).catch((err)=>{
                reject((err))
                console.log(err)})
            
            })
            return promise 

    }

    delFood(id:string){
        const promise=   new Promise<any>((resolve, reject) => {
        this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {return userOrgId})
        .then ((organisationId)=>{
          this.afs.doc("dayCareData/"+organisationId+"/food/"+id).delete()
          .then(() => {
            resolve()
          })
          .catch(error =>{
           reject(error)
          });
        }).catch((err)=>{console.log(err)})
      
    })
        return promise
    }

    getAllFood(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.foodArray.length>0 ? resolve (this.foodArray):null
                    this.afs.collection("dayCareData/" + userOrgId +"/food").ref.onSnapshot
                    ((snapshot) => {
                
                    this.foodArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.foodArray.push(doc.data());
                    });
                    resolve(this.foodArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     getAllAreas(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.areasArray.length>0 ? resolve (this.areasArray):null
                    this.afs.collection("dayCareData/" + userOrgId +"/areas").ref.onSnapshot
                    ((snapshot) => {
                
                    this.areasArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.areasArray.push(doc.data());
                    });
                    resolve(this.areasArray);
                    this.areasValue.next(this.areasArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }
     getAllCalendarType(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.calendarTypeArray.length>0 ? resolve (this.calendarTypeArray):null
                    this.afs.collection("dayCareData/" + userOrgId +"/calendarTypes").ref.onSnapshot
                    ((snapshot) => {
                
                    this.calendarTypeArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.calendarTypeArray.push(doc.data());
                    });
                    resolve(this.calendarTypeArray);
                    this.calendarTypeValue.next(this.calendarTypeArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     getAllDevices(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.devicesArray.length>0 ? resolve (this.devicesArray):null
                    this.afs.collection("dayCareData/" + userOrgId +"/devices").ref.onSnapshot
                    ((snapshot) => {
                
                    this.devicesArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.devicesArray.push(doc.data());
                    });
                    resolve(this.devicesArray);
                    this.devicesValue.next(this.devicesArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }
     createDevice(name:string,type:string){
  
        const promise=   new Promise<any>((resolve, reject) => {
        this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {return userOrgId})
        .then ((organisationId)=>{
          this.afs.doc("dayCareData/"+organisationId+"/devices/"+ this.createId()).set({name:name,type:type},{ merge: true })
          .then(() => {
            resolve()
          })
          .catch(error =>{
           reject(error)
          });
        }).catch((err)=>{console.log(err)})
      
    })
        return promise
    }

    delDevice(id:string){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {return userOrgId})
            .then ((organisationId)=>{
              this.afs.doc("dayCareData/"+organisationId+"/devices/"+ id).delete()
              .then(() => {
                resolve()
              })
              .catch(error =>{
               reject(error)
              });
            }).catch((err)=>{console.log(err)})
          
        })
            return promise
    }

    passDevice(device:any=null){
        if (device) {
            this.device=device
            return;
        }
        return this.device;
    }

    passCustomReport(customReport:any=null){
        if (customReport) {
            this.customReport=customReport
            return;
        }
        return this.customReport;
    }

    passChildren(children:any=null){
        if (children) {
            this.childrenAfterFilter=children
            return;
        }
        return this.childrenAfterFilter;
    }
    resetChildrenAfterFilter(){
        this.childrenAfterFilter=null
    }
    passFood(item:any=null){
        if (item) {
            this.foodItem=item
            return;
        }
        return this.foodItem;
    }

     createId(){
       return this.afs.createId(); 
     }

     uploadFamily(family:any){
        
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {return userOrgId})
            .then ((organisationId)=>{
                        var batch = this.afs.firestore.batch()
                        family.forEach((element, index, object) => {
                            if (element.updateStatus==="new"){
                                batch.set(this.afs.firestore.doc("dayCareData/" +organisationId+"/family/"+element.id),element);
                            }
                            if (element.updateStatus==="changed"){
                                if (element.status !== undefined && element.status === "delete"){
                                    delete element['status']
                                    batch.update(this.afs.firestore.doc("dayCareData/" +organisationId+"/family/"+element.id),element);
                                    object.splice(index, 1);
                                }
                                else {
                                       batch.update(this.afs.firestore.doc("dayCareData/" +organisationId+"/family/"+element.id),element);}
                            }
                            if (element.updateStatus==="delete"){
                                batch.delete(this.afs.firestore.doc("dayCareData/" +organisationId+"/family/"+element.id));
                                object.splice(index, 1);
                            }
                           
                        });
                        return batch.commit()
                    })
                   
        
     }

     getFamily(idArray:any){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                     console.log(idArray)
                    console.log(idArray.length)
                    idArray.length>0 ? null:resolve ();
                    var familyArray=[]
                    idArray.forEach((element,idx,array) => {
                        this.afs.doc("dayCareData/" + userOrgId +"/family/"+element).ref.get().then((doc)=> {
                            if (doc.exists){
                                var obj=doc.data()
                                obj['updateStatus']='fromCloud'
                                familyArray.push(obj)
                            }   
                            idx === array.length - 1 ? resolve(familyArray):null;        
                        }).catch((err)=>{reject(err);})
                    });
                }).catch((error)=>{reject(error);});
            })
                return promise
     }

     getFamilyObj(idArray:any){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    var family={}
                    Object.keys(idArray).forEach((element,idx,array) => {
                        this.afs.doc("dayCareData/" + userOrgId +"/family/"+element).ref.get().then((doc)=> {
                            if (doc.exists){
                                var obj=doc.data()
                                family[element]=obj
                            }   
                            idx === array.length - 1 ? resolve(family):null;        
                        }).catch((err)=>{reject(err);})
                    });
                }).catch((error)=>{reject(error);});
            })
                return promise
     }
     

     getAllLists(type:string=null){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.listsArray.length>0 ? resolve (this.listsArray):null
                    this.afs.collection("dayCareData/" + userOrgId +"/lists").ref.onSnapshot
                    ((snapshot) => {
                        
                    this.listsArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.listsArray.push(doc.data());
                    });
                    this.listsValue.next(this.listsArray);
                    resolve(this.listsArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     getAllMessages(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.messagesArray.length>0 ? resolve (this.messagesArray):null
                    this.afs.collection("dayCareData/" + userOrgId +"/preparedMessages").ref.onSnapshot
                    ((snapshot) => {
                        
                    this.messagesArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.messagesArray.push(doc.data());
                    });
                    this.messagesValue.next(this.messagesArray);
                    resolve(this.messagesArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     createMsgForChildrenArray(childrenArray:[any],msg:any){
        const promise=   new Promise<any>(async (resolve, reject) => {
                var batch = this.afs.firestore.batch();
                try {
                    var organisationId= await  this.userOrgIdService.getUserOrgId();
                    childrenArray.forEach(async (child,index) => {
                        msg['childId']=child.id 
                        console.log(msg)
                        batch.set(this.afs.firestore.doc("dayCareData/"+organisationId+"/messages/"+this.createId()),msg)
                        if ((index+1)%19==0){
                            try {
                              await batch.commit()
                              batch = this.afs.firestore.batch();
                            }
                            catch (err) {
                              console.log(err)
                              return reject()
                            }  
                            
                          }    
                          if (index==childrenArray.length-1){
                             batch.commit().then(()=> {return resolve()}).catch((err)=>{return reject(err)})
                          }
                    });
                   
                } catch (error) {
                    console.log(error);
                    reject()
                }})
    
                return promise;
    }

    async getMessages(time:string){
        try {
          var orgId=await   this.userOrgIdService.getUserOrgId()
         }
         catch (err){
          console.log(err)
          return
         }
         const promise = new Promise<any>(
          (resolve, reject) => {
            var reports=[]       
            this.afs.collection("/dayCareData/"+orgId+"/messages/").ref.orderBy('time','desc').where("time","<",time).limit(5).get().then((snapshot)=>{
              snapshot.docs.forEach((doc) => {
                var obj=doc.data()
                obj['day']=new Date(Number(obj.time)).getDate().toString()+new Date(Number(obj.time)).getMonth().toString()
                
                reports.push(obj)
              }
              
            );
            
            
               
            resolve(reports)
            }).catch((err)=>{
            reject(err)
            console.log(err)
          })
          })
          return promise
          
        
        }

     getAllDiseases(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.messagesArray.length>0 ? resolve (this.messagesArray):null
                    this.afs.collection("dayCareData/" + userOrgId +"/diseases").ref.onSnapshot
                    ((snapshot) => {
                        
                    this.diseasesArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.diseasesArray.push(doc.data());
                    });
                    this.diseasesValue.next(this.diseasesArray);
                    resolve(this.diseasesArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     getAllEquipmentLists(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.equipmentListsArray.length>0 ? resolve (this.listsArray):null
                    this.afs.collection("dayCareData/" + userOrgId +"/equipmentLists").ref.onSnapshot
                    ((snapshot) => {
                        
                    this.equipmentListsArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.equipmentListsArray.push(doc.data());
                    });
                    this.equipmentListsValue.next(this.equipmentListsArray);
                    resolve(this.equipmentListsArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }


     getAllChildren(onlyAvailable:boolean=true){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.childrenArray.length>0 && this.onlyAvailableChildrenInDB==onlyAvailable ? resolve (this.childrenArray):null
                    var collection =  this.afs.collection("dayCareData/" + userOrgId +"/children").ref
                    var query:Query=collection;
                    if (onlyAvailable==true) {
                        query=collection.where('status','==',true)
                    }
                    query.onSnapshot
                    ((snapshot) => {
                    this.childrenArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.childrenArray.push(doc.data());
                    });
                    this.onlyAvailableChildrenInDB=onlyAvailable
                    this.childrenValue.next(this.childrenArray);
                    resolve(this.childrenArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     async updateEnter(time:Date,status:boolean,employee:any,areaId:string){
        var time=new Date(time)
       try {
         var organisationId =await this.userOrgIdService.getUserOrgId()
         }
         catch (err) {
           console.log(err)
           return
         }  
       var timeEntry={}
       timeEntry[time.getTime().toString()]={
         employeeId:employee.employeeId,
         name:employee.name,
         enter:status,
         time:time.getTime(),
         eventFrom:areaId
    
       }
       var batch = this.afs.firestore.batch();
       if (status) {
         var checkIn={}
         checkIn[employee.employeeId]={
           employeeId:employee.employeeId,
           name:employee.name,
           time:time.getTime(),
           eventFrom:areaId
    
         }
         batch.set(this.afs.firestore.doc("dayCareData/"+organisationId+"/employeeCheckIn/"+startOfDay(time).getTime().toString()),checkIn,{merge: true})
       }
       else{
         batch.set(this.afs.firestore.doc("dayCareData/"+organisationId+"/employeeCheckIn/"+startOfDay(time).getTime().toString()),{[employee.employeeId]: firebase.firestore.FieldValue.delete()},{merge: true})
     }
     
      
       batch.set(this.afs.firestore.doc("dayCareData/"+organisationId+"/timeEntry/"+startOfMonth(time).getTime().toString()),timeEntry,{merge: true})
    
       batch.commit().then(()=>{
       }).catch((err)=>{console.log(err);
       })
    
    }

     getAllInActiveChildren(){
        const promise=   new Promise<any>((resolve, reject) => {
            if (this.inactiveChildrenArray.length>0){
                resolve(this.inactiveChildrenArray);
            }
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    var collection =  this.afs.collection("dayCareData/" + userOrgId +"/children").ref
                    var query:Query=collection;
                    query=collection.where('status','==',false)
                    query.onSnapshot
                    ((snapshot) => {
                    this.inactiveChildrenArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.inactiveChildrenArray.push(doc.data());
                    });
                    this.inactiveChildrenValue.next(this.inactiveChildrenArray);
                    resolve(this.inactiveChildrenArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     getAllFrozenChildren(){
        const promise=   new Promise<any>((resolve, reject) => {
            if (this.frozenChildrenArray.length>0){
                resolve(this.frozenChildrenArray);
            }
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    var collection =  this.afs.collection("dayCareData/" + userOrgId +"/children").ref
                    var query:Query=collection;
                    query=collection.where('status','==','frozen')
                    query.onSnapshot
                    ((snapshot) => {
                    this.frozenChildrenArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                            this.frozenChildrenArray.push(doc.data());
                    });
                    this.frozenChildrenArrayValue.next(this.frozenChildrenArray);
                    resolve(this.frozenChildrenArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }


     getNotComingFile(){
        const promise=   new Promise<any>(async (resolve, reject) => {
        if (this.notComingObj!=null){
            resolve(this.notComingObj)
        }
        try {
            var orgId=await this.userOrgIdService.getUserOrgId()
           }
           catch (err){
            console.log(err)
            reject(err)
           }
           this.afs.collection("dayCareData/" + orgId +"/notComing").ref.where("end",">",(new Date).getTime()).onSnapshot
                    ((snapshot) => {
                        
                    this.notComingObj={}
                    snapshot.docs.forEach((doc, idx, array) => {
                            if (doc.data().start<(new Date).getTime()){
                                this.notComingObj[doc.data().childId]=doc.data();
                            }
                           
                    });
                   
                    this.notComingValue.next(this.notComingObj)
                    resolve(this.notComingObj);
                }
    
                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
        })
        return promise
    }

    getComingLateFile(){
        const promise=   new Promise<any>(async (resolve, reject) => {
        if (this.comingLateObj!=null){
            resolve(this.comingLateObj)
            return
        }
        try {
            var orgId=await this.userOrgIdService.getUserOrgId()
           }
           catch (err){
            console.log(err)
            reject(err)
           }
           this.afs.collection("dayCareData/" + orgId +"/comingLate").ref.where("arriveAt",">",startOfDay(new Date()).getTime()).where("arriveAt","<",endOfDay(new Date()).getTime()).onSnapshot
                    ((snapshot) => {
                        
                    this.comingLateObj={}
                    snapshot.docs.forEach((doc, idx, array) => {
                                this.comingLateObj[doc.data().childId]=doc.data();
                           
                    });
                   
                    this.comingLateValue.next(this.comingLateObj)
                    resolve(this.comingLateObj);
                }
    
                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
        })
        return promise
    }

     getCheckInReport(reportId:string){
        const promise=   new Promise<any>((resolve, reject) => {
            if (this.checkInObj!==null && this.reportId===reportId){
                resolve (this.checkInObj)
                return
            }
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {return userOrgId})
            .then ((organisationId)=>{
              this.afs.doc("dayCareData/"+organisationId+"/checkIn/"+reportId).ref.onSnapshot
              ((doc) => {
                if (doc.exists){
                    this.reportId=reportId
                    this.checkInObj=doc.data();
                    this.checkInValue.next(doc.data());
                    resolve(doc.data())
                }
                else {
                    this.checkInValue.next({});
                    this.checkInObj={}
                    resolve({})
                  
                }
                
              })
           
            }).catch((err)=>{
                reject((err))
                console.log(err)})
            
            })
            return promise 
    }
    getCheckInByDateAndClassID(start:number,finish:number){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    var collection =  this.afs.collection("dayCareData/"+userOrgId+"/checkIn/").ref
                    var query:Query=collection;
                    query=collection.where('id','>=',start).where('id','<=',finish)
                    
                    query.get().then
                    ((snapshot) => {
                    var reportsArray=[];
                    reportsArray.length=0;
                    snapshot.docs.forEach((doc, idx, array) => {
                        reportsArray.push(doc.data());
                    });
                    resolve(reportsArray);
                }

                    ,err  => {
                    reject(err);
                    console.log(err);
                    })
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     getCheckOutReport(reportId:string){
        const promise=   new Promise<any>((resolve, reject) => {
            if (this.checkOutObj!==null && this.reportId==reportId){
                resolve (this.checkOutObj)
                return
            }
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {return userOrgId})
            .then ((organisationId)=>{
              this.afs.doc("dayCareData/"+organisationId+"/checkOut/"+reportId).ref.onSnapshot
              ((doc) => {
                if (doc.exists){
        
                    this.checkOutValue.next(doc.data());
                    this.checkOutObj=doc.data()
                    this.reportId=reportId
                    resolve(doc.data())
                }
                else {
                    this.checkOutValue.next({});
                    this.checkOutObj={}
                    resolve({})
                  
                }
                
              })
           
            }).catch((err)=>{
                reject((err))})
            
            })
            return promise 
    }

     getMonthTimeEntryReport(reportId:string){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                this.afs.doc("dayCareData/" + userOrgId +"/timeEntry/"+reportId).ref.get().then((doc)=> {
                    if (doc.exists){
                        resolve(doc.data());
                    }
                    reject("Doc not exists");}) 
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     delEventFromReport(reportId:string,eventId:string){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                this.afs.doc("dayCareData/" + userOrgId +"/timeEntry/"+reportId).ref.update({[eventId]: firebase.firestore.FieldValue.delete()}).then(()=> {

                        resolve();
                    
                   }) 
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }
     saveNewTimeEntry(reportId:string,obj:any){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                this.afs.doc("dayCareData/" + userOrgId +"/timeEntry/"+reportId).ref.set(obj,{merge:true}).then(()=> {

                        resolve();
                    
                   }) 
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }
   

     getChildrenInTheClass(areaId:string,reportId:string){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                this.afs.doc("dayCareData/" + userOrgId +"/areas/"+areaId+"/childrenInTheClass/"+reportId).ref.get().then((doc)=> {
                    if (doc.exists){
                        resolve(doc.data());
                    }
                    else {
                        reject("Doc not exists")
                    }
                    ;}) 
                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     getActiveChildByAreaId(areaId:string){
        const promise=   new Promise<any>((resolve, reject) => {
       

            
            this.getAllChildren().then((childrenArray)=>{
                areaId ? resolve(childrenArray.filter(s => s.areaId==areaId)) : resolve(childrenArray)
            })
            .catch((err)=>{reject(err)})
        
        })
            return promise
     }

     getFrozenChildByAreaId(areaId:string){
        const promise=   new Promise<any>((resolve, reject) => {
            this.getAllFrozenChildren().then((childrenArray)=>{
                resolve(childrenArray.filter(s => s.areaId==areaId));
            })
            .catch((err)=>{reject(err)})
        })
            return promise
     }
     

    //  getChildByAreaId(areaId:string=null){
    //     const promise=   new Promise<any>((resolve, reject) => {
    //         this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
    //                 var collection =  this.afs.collection("dayCareData/" + userOrgId +"/children").ref.where("status","==",true)
    //                 var query:Query=collection;
    //                 areaId ?  query=collection.where('areaId','==',areaId) : null
    //                 query.onSnapshot
    //                 ((snapshot) => {
    //                 var childrenArray=[];
    //                 childrenArray.length=0;
    //                 snapshot.docs.forEach((doc, idx, array) => {
    //                         childrenArray.push(doc.data());
    //                 });
    //                 resolve(childrenArray);
    //             }

    //                 ,err  => {
    //                 reject(err);
    //                 console.log(err);
    //                 })
    //             }).catch((error)=>{reject(error);});
    //         })
    //             return promise
        
    //  }

    //only with status true
     getChildById(id:string){
        const promise=   new Promise<any>((resolve, reject) => {
                this.getAllChildren().then((childrenArray)=>{
                    resolve(childrenArray.filter(s => s.id==id)[0]);
                })
                .catch((err)=>{reject(err)})
            })
                return promise

        
     }

     //deleted too
     getAllChildById(id:string,onlyAvailable:boolean){
        const promise=   new Promise<any>((resolve, reject) => {
                this.getAllChildren(onlyAvailable).then((childrenArray)=>{
                    resolve(childrenArray.filter(s => s.id==id)[0]);
                })
                .catch((err)=>{reject(err)})
            })
                return promise

        
     }

     passChild(child:any=null){
         if (child) {
             this.child=child
             return;
         }
         return this.child;
     }

     passEmployee(employee:any=null){
        if (employee) {
            this.employee=employee
            return;
        }
        return this.employee;
    }

     getNewChildForm(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.afs.doc("dayCareData/" + userOrgId +"/forms/registerForm").ref.get().then((doc)=> {
                    if (doc.exists){
                        resolve(doc.data());
                    }
                    reject("Doc not exists");
                   
                    
                }).catch((err)=>{reject(err);})

                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     getNewFamilyForm(){
        const promise=   new Promise<any>((resolve, reject) => {
            this.familyForm ? resolve(this.familyForm) : null
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.afs.doc("dayCareData/" + userOrgId +"/forms/familyMemberForm").ref.get().then((doc)=> {
                    if (doc.exists){
                        this.familyForm=doc.data()
                        resolve(doc.data());
                    }
                    reject("Doc not exists");
                   
                    
                }).catch((err)=>{reject(err);})

                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

     getDayEvent(childId:string,dayId:string){
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.afs.doc("dayCareData/" + userOrgId +"/children/"+childId+"/reports/"+dayId).ref.onSnapshot
                     ((doc)=> {
                    if (doc.exists){
                        resolve(doc.data());
                    }
                    resolve(null);
                   
                    
                }),((err)=>{reject(err);})

                }).catch((error)=>{reject(error);});
            })
                return promise
        
     }

      getForm(formName:string) {

        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.afs.doc("dayCareData/" + userOrgId + "/forms/"+formName).ref.get().then((doc)=> {
                    if (doc.exists){
                        var formList=[];
                        for (const prop in doc.data()) {
                            formList=[...formList,{id:prop,value:doc.data()[prop]}]
                            
                          }
                        resolve(formList);
                    }
                    reject("Doc not exists");
                   
                    
                }).catch((err)=>{reject(err);})

                }).catch((error)=>{reject(error);});
            })
                return promise
        
    
      }

      getList(listName:string) {
        const promise=   new Promise<any>((resolve, reject) => {
            this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {
                    this.afs.doc("dayCareData/" + userOrgId + "/lists/"+listName).ref.onSnapshot
                    ((doc)=> {
                    if (doc.exists){
                      
                        resolve(doc.data());
                    }
                    reject("Doc not exists");
                    
                   
                    
                },err =>{reject(err);})

                }).catch((error)=>{reject(error);});
            })
                return promise
        
    
      }

      requestingEquipmentReport(value:any){
  
        const promise=   new Promise<any>((resolve, reject) => {
        this.userOrgIdService.getUserOrgId().then((userOrgId:string)=> {return userOrgId})
        .then ((organisationId)=>{
          this.afs.doc("dayCareData/"+organisationId+"/requestingEquipment/"+this.createId()).set(value)
          .then(() => {
            resolve()
          })
          .catch(error =>{
           reject(error)
          });
        }).catch((err)=>{console.log(err)})
      
    })
        return promise
    }




    navigateBack(local:string=null,times:number=1){
        local===null ? local=this.location.path() :null
        for (let index = 0; index < times; index++) {
            var n = local.lastIndexOf('/');
            local = local.substring(0,n);
            
        }
        this.router.navigate([local]);
    }

        


}