import { NgModule } from "@angular/core";
import { SideMenuComponent } from "./side-menu.component";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations:[
      
        SideMenuComponent
        



    ],imports:[
        BrowserModule,
        RouterModule,
        TranslateModule
        
    ],
    exports: [
        SideMenuComponent
        
    ]
  
})
export class SideMenuSharedModul {

}