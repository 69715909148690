import { NgModule } from "@angular/core";

import { StringToDateHoursOnly } from "./stringToDateHoursOnly.pipe.";



@NgModule({
    declarations: [StringToDateHoursOnly],
    exports: [StringToDateHoursOnly],
    imports:[]
})
export class StringToDateHoursOnlySharedModul {}