import { Component, OnInit ,ViewChild} from '@angular/core';
import { SuccessModalComponent } from './modals/success-modal/success-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from './services/modal.service';
import {TranslateService} from '@ngx-translate/core';
import { ChangeLan } from './services/changeLan.service';
import {CookieService} from 'ngx-cookie-service';
import { MetaDataService } from './services/meta-data.service';
import { ReloadStartOfTheDayService } from './services/reload-start-of-the-day.service';






@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
  
})
export class AppComponent implements OnInit{
  title = 'kidcare';

  @ViewChild('dashboardRow',{static:false}) dashboardRow: any;
  //userLoginedValue:boolean = false;
  constructor(private cookieService: CookieService,
              private reloadStartOfTheDayService:ReloadStartOfTheDayService,
              private ms:MetaDataService,
              private modalGlobalService: ModalService,
              private lanSer:ChangeLan,
              private modalService: NgbModal,private translate: TranslateService,private changeLanSer: ChangeLan)  {
    translate.setDefaultLang('en');
  }

    // detectUrl(){
    //   switch (window.location.hostname) {
    //     case 'localhost':
    //       this.domainService.changeDomain('bring4baby') 
    //       return 'bring4baby'
    //       break;
    //     case 'bring4baby.com':
    //        this.domainService.changeDomain('bring4baby')  
    //       return 'bring4baby'
    //       break;
      
    //     default: return ''
    //       break;
    //   }
    //   console.log(window.location.hostname);
    // }

    ngOnInit(){
      //var prefix=this.detectUrl();
      this.reloadStartOfTheDayService.checkIfReload()
     // this.lanFunction();
      this.serviceWorkerUnregistered();
      setTimeout(()=>{   this.rtl()}, 1)
    
     }

     rtl(){
      this.lanSer.rtlStatus.subscribe((status)=>{
        status ? this.dashboardRow.nativeElement.classList.add('flex-row-reverse'):this.dashboardRow.nativeElement.classList.remove('flex-row-reverse');
      })
     }

     serviceWorkerUnregistered(){
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistrations().then(function (registrations) {
            for (const registration of registrations) {
              console.log('serviceWorker unregistered');
              registration.unregister();
            }
          });
        }
  }
 

  

    

    openFormModal() {
      const modalRef = this.modalService.open(SuccessModalComponent);
      // modalRef.componentInstance.id = 11; // should be the id
      
      modalRef.result.then((result) => {
      }).catch((error) => {
      });
    }
    removeModal(){
      this.modalGlobalService.destroy();
    }
 
    back(){
     this.ms.navigateBack();
    }
   
}


