import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { mouseOverDirective } from './mystyles/mouseOver.directive';
import { SuccessModalComponent } from './modals/success-modal/success-modal.component';
import { ModalService } from './services/modal.service';
import { DomService } from './services/dom.service';
import { ChangeLan } from './services/changeLan.service';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { SpinnerModelComponent } from './modals/spinner-model/spinner-model.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GetUserOrgIdService } from './services/getUserOrgId.service';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { SharedModule } from '../shared/shared.module';
import { NvbarModule } from './nvbar/nvbar.module';
import { NavbarService } from './services/navBar.service';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { DeviceDetectorModule } from 'ngx-device-detector';
// import { AngularFireFunctionsModule,FunctionsRegionToken } from '@angular/fire/functions';
import { PrintService } from './services/print.service';
import { StringToDate } from './pipes/stringToDate.pipe';
import { ImageService } from './services/image.service';
import { DomainService } from './services/domain.service';
import { MetaDataService } from './services/meta-data.service';
import { DownloadModelComponent } from './modals/download-model/download-model.component';
import { BalanceService } from './services/balance.service';
import { NetworkStatusComponent } from './helper-components/network-status/network-status.component';
import { FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { BalanceGuard } from './services/balance-guard.service';
import { NewUserOrExistsComponent } from './helper-components/new-user-or-exists/new-user-or-exists.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LikeComponent } from './modals/like/like.component';
import { WindowService } from './services/window.service';
import { AngularFireFunctionsModule } from '../../node_modules/@angular/fire/functions';
import { BellListenerComponent } from './bell-listener/bell-listener.component';
import { HelpComponent } from './help/help.component';
import { ReloadStartOfTheDayService } from './services/reload-start-of-the-day.service';
import { CustomReuseStrategy } from './services/customReuseStrategy';
import { RouteReuseStrategy } from '../../node_modules/@angular/router';
import { MultisiteComponent } from './multisite/multisite.component';
import { MultiSiteGuard } from './services/multisite-guard.service';
import { SideMenuSharedModul } from './side-menu/side-menu-shared';
import { ArrayOfDatesTostringToDates } from './pipes/arrayOfDatesTostringToDates.pipe';














@NgModule({
  declarations: [
    AppComponent,
    mouseOverDirective,
    SuccessModalComponent,
    ErrorModalComponent,
    SpinnerModelComponent,
    DownloadModelComponent,
    NetworkStatusComponent,
    NewUserOrExistsComponent,
    LikeComponent,
    BellListenerComponent,
    HelpComponent,
    MultisiteComponent
    

  
    


    
  ],
  imports: [
    DeviceDetectorModule.forRoot(),
    NvbarModule,
    SideMenuSharedModul,
    SharedModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase, 'InfoTagApp'),
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    AngularFirestoreModule.enablePersistence(), // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AppRoutingModule,
    HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        
     //   ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),

        
        BrowserAnimationsModule
  
    

  ],
  providers: [ImageService,DomainService,StringToDate,AuthService,GetUserOrgIdService,AuthGuard,MetaDataService,BalanceGuard,
    ArrayOfDatesTostringToDates,
    ModalService,DomService,ChangeLan,CookieService,CanDeactivateGuard,NavbarService,PrintService,BalanceService, { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    ReloadStartOfTheDayService,
    WindowService
    //{ provide: FunctionsRegionToken, useValue: 'us-central1' }
    ,{ provide: FirestoreSettingsToken, useValue: {} }],

  bootstrap: [AppComponent],
  
  entryComponents: [
    SuccessModalComponent,
    SpinnerModelComponent,
    ErrorModalComponent,
    LikeComponent,
    DownloadModelComponent

  ]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
