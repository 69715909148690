import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { GetUserOrgIdService } from '../services/getUserOrgId.service';
import { AngularFirestore } from '../../../node_modules/@angular/fire/firestore';
import { NgbModal } from '../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../services/auth.service';
import { Subscription } from '../../../node_modules/rxjs';



;

@Component({
  selector: 'app-bell-listener',
  templateUrl: './bell-listener.component.html',
  styleUrls: ['./bell-listener.component.css']
})
export class BellListenerComponent implements OnInit {


  constructor(
    private userOrgIdService:GetUserOrgIdService,
    private readonly afs: AngularFirestore,
    private authService:AuthService,
    private modalService: NgbModal,
    private zone: NgZone
  ) { }
  currentSound: HTMLAudioElement;
  currentUserPositionSubscribe: Subscription;

  areaName=''
  @ViewChild('alarm',{static:false}) alarm;

  ngOnInit() {
  this.authService.isAuthenticated().then((status)=>{
   if  (status){
    this.listenToBell()
   }
   else{
    this.currentUserPositionSubscribe=this.authService.currentUserPosition.subscribe(status => {
      if (status===true) {
        this.listenToBell()
        this.currentUserPositionSubscribe ? this.currentUserPositionSubscribe.unsubscribe() : null;
         
      }})

   }
  }).catch((err)=>{})
 
  }

  async listenToBell(){
    try {
      var organisationId=await this.userOrgIdService.getUserOrgId()
     }
     catch (err){
       console.log(err)
      return
     }
     var id=new Date().getTime()
     this.afs.collection("dayCareData/"+organisationId+"/bell").ref.orderBy("id",'desc').where("id",">",id).limit(1).onSnapshot
     ((snapshot) => {
       if (snapshot.docs[0]){
        this.areaName=snapshot.docs[0].data().areaName
        this.modalService.open(this.alarm, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true,backdrop: 'static' });
        this.playSound('siren')
        this.zone.run(() => {
        });
        
        
       }
     
     })
     ,err  => {
      console.log(err);
      }

  }

  playSound(sound) {
    sound = "../../assets/sounds/" + sound + ".mp3";
    this.currentSound=new Audio(sound)
    sound && this.currentSound.play().then().catch((err)=>{
      console.log(err)
    })
  }

  finish(){
    this.currentSound && this.currentSound.pause()
  }
}
