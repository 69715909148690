import { PipeTransform, Pipe } from "@angular/core";
@Pipe({
    name:'StringToDate'
})

export class StringToDate implements PipeTransform {
    //constructor (){}
    transform (value:any){
      if (value) {
        var dateFormat = require('dateformat');
        var date = new Date(Number(value));
        return (dateFormat(date, "HH:MM:ss dd/mm/yyyy").toString());
      }
    }
}
