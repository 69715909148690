import { Injectable } from '@angular/core';
import { startOfDay,differenceInMilliseconds } from 'date-fns'
import { WindowService } from './window.service';

@Injectable()
export class ReloadStartOfTheDayService {

  constructor(
    private ws:WindowService,
   
  ) {}

   today=null
  

  checkIfReload(){
    this.today=startOfDay(new Date()).getTime()
    setInterval(()=>{
      
      if (this.today && this.today!=startOfDay(new Date()).getTime()){
        console.log('New Day Reload')
         this.reload()
      }
    },5000)
  }
  
  reload(){
    setTimeout(() => {
      //document.location.reload()
      this.ws.windowRef.location.reload(true)
    }, 2000);
  }
}