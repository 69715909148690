import { Injectable } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/auth';
import {Router, NavigationEnd} from "@angular/router"
import { AngularFirestore } from "@angular/fire/firestore";
import { CookieService } from "ngx-cookie-service";


@Injectable()
export class GetUserOrgIdService {
    constructor(
      private router: Router,
      private cookieService: CookieService,
      private readonly afs: AngularFirestore,
      private readonly afAuth: AngularFireAuth){};
    userOrgId:string='';
    counter=0;
    uid:string=null
    multisiteIds=[]

    getUserOrgId()  {       
      const promise = new Promise<any>(
          (resolve, reject) => {
            if  (this.userOrgId!='') {
              resolve(this.userOrgId);
            }
            else{
              this.checkIfLogIn().then(()=>{
                this.getUserMultisiteOrgIds().then((arr)=>{
                  this.userOrgId=this.checkLastOrganization(arr)
                  resolve(this.userOrgId)})
               .catch((err)=>{
                  reject (err)
                })
              })
           
            }
    });
    return promise;
    }


    checkLastOrganization(arr:[string]){
      if (!this.cookieService.check('lastUsedOrganizationId')){
           return arr[0]
      }
      if (arr.includes(this.cookieService.get('lastUsedOrganizationId'))){
        return this.cookieService.get('lastUsedOrganizationId')
      }
      return arr[0]
    }


    checkIfUserOrgIdExists(){
      if  (this.userOrgId!="") {
       return true
      }
      return false
    }

    checkIfMultiSiteMoreThenOne(){
      if (this.multisiteIds.length>1) {
        return true
      }
      return false
    }

    checkIfMultiSite()  {       
      const promise = new Promise<any>(
          (resolve, reject) => {
            
              this.checkIfLogIn().then(()=>{
                this.afAuth.auth.currentUser.getIdTokenResult()
                .then((idTokenResult)=>{
                  idTokenResult.claims.multisite ? resolve(true):resolve(false)
                   
                }).catch((err)=>{
                  resolve (false)
                }).catch(()=>{
                  resolve(false)
                })
              })
           
            
    });
    return promise;
    }

    setOrgId(orgId:string){
      this.userOrgId=orgId
      this.cookieService.set('lastUsedOrganizationId',orgId,9999,'/');
      //this.router.navigate(['./dashboard'],{ state: { example: 'bar' }}) 
      this.router.navigate(['./dashboard'],{queryParams:{skipCustomReuseStrategy:true}})
      
      
    }



    getUserMultisiteOrgIds(){
      const promise = new Promise<any>(
        async (resolve, reject) => {
            if (this.multisiteIds.length>0){
              resolve(this.multisiteIds)
            }
            try {
              var uid=await this.getUserUID()
            } catch (error) {
              reject(error)
            }
            this.afs.doc("/multisiteUsers/"+uid).ref.get().then((doc) => {
              if (doc.exists){
                doc.data().orgIds ? this.multisiteIds=doc.data().orgIds : this.multisiteIds=[]
                  resolve(this.multisiteIds);

              }
              else{
                  reject("Not exists")
              }})
        })
        return promise;
    }

    getUserUID()  {       
      const promise = new Promise<any>(
          (resolve, reject) => {
            if  (this.uid) {
              resolve(this.uid);
            }
            else{
              this.checkIfLogIn().then(()=>{
                this.uid=this.afAuth.auth.currentUser.uid
                resolve( this.afAuth.auth.currentUser.uid)
                }).catch((err)=>{
                  reject (err)
                })}})
    return promise;
    }
  

    checkIfLogIn(){
      const promise = new Promise<any>((resolve, reject) => {
        var subs=this.afAuth.auth.onAuthStateChanged((user)=> {
          if (user) {
           subs();
           resolve();  
          } else {
            subs();
            reject();
          }
        });
      })
      return promise}
    
     
    
}