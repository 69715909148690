import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable()
export class AuthService {
  constructor( 
    public afAuth: AngularFireAuth,
    private readonly afs: AngularFirestore,
    private zone: NgZone,
    private router: Router){}
    private connectSource = new BehaviorSubject(null);
    currentUserPosition = this.connectSource.asObservable();
    isLogIn:boolean=false;
    GooglePromise: Promise<any>
    onAuthStateChangedSubscribe:any;

  isAuthenticated() {
    const promise = new Promise(
      (resolve, reject) => {
        if  (this.afAuth.auth.currentUser) {
          this.afAuth.auth.currentUser.getIdTokenResult().then((token)=>{
         //   console.log(token)
          }).catch((err)=>{
            console.log(err)
          })
          if (this.connectSource.getValue()!=true){
               this.connectSource.next(true);}
          resolve(true);
          //this.isLogIn=true;

        }
        else {
              
          this.onAuthStateChangedSubscribe=this.afAuth.auth.onAuthStateChanged(user => { 
                if (user) {
                  if (this.connectSource.getValue()!=true){
                    this.connectSource.next(true);
                    
       
                    }
                  //this.isLogIn=true;
                  resolve(true);
                } else {
                  if (this.connectSource.getValue()==true){
                    this.connectSource.next(false);}
                  //this.isLogIn=false;
                  resolve(false)
          
                }
                
              }
            );
            }
          }
    );
    return promise;
  }

 userStatus(status:boolean){
  this.connectSource.next(status);
 }

 checkVerificationEmail(sendEmail:boolean=false){
  const promise = new Promise<any>(
    (resolve, reject) => {
      var user = this.afAuth.auth.currentUser;
        if (user.emailVerified){
          resolve();
        }
        else{
          if (sendEmail){
              user.sendEmailVerification().then(function() {
                reject();
              }).catch(function(error) {
                console.log(error);
                resolve();
              });
          }
          else{
             reject();
          }
        }
        
        
      
    });
    return promise;

}

 getUserUid(){
  const promise = new Promise<any>(
    (resolve, reject) => {
  this.isAuthenticated().then((status)=>{
    if (status){
      resolve(this.afAuth.auth.currentUser.uid);
    }
    else{
      reject()
    }
  })
   
 });
 return promise;
}
  
  doCreateUserWithEmailAndPassword(email:string,password:string,displayName:string){
    const promise= new Promise<any>((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(email,password).then((user)=> {
        this.updateUserProfile(this.afAuth.auth.currentUser,displayName);
        resolve();
        // this.checkIfUserOrganisationIdExist(this.afAuth.auth.currentUser.uid).then(()=>{
        //   resolve();
        // }).catch((message:string)=>reject(message))
  
  
    }, (error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      reject(error.message)
  });

    })
    return promise;

  }

  updateUserProfile(user:any,displayName:string){
  
    user.updateProfile({
      displayName: displayName
    })
  }

  

  doGoogleLogin(){
    const promise= new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        if (res.credential) {
          resolve(res.credential);
          // this.checkIfUserOrganisationIdExist(this.afAuth.auth.currentUser.uid).then(
          //   () => {
          //     resolve(res.credential);
          //   }).catch((message:string)=>{
          //     reject(message);
          //   });;

        }
      })
      .catch((error)=> {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        reject(errorMessage);
        // ...
      });
    })
    return promise; 
  }

 

  loginWithUserPassword(emailString:string,passwordString:string){
    const promise= new Promise<any>((resolve, reject) => {
          this.afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          this.afAuth.auth.signInWithEmailAndPassword(emailString, passwordString).catch((error)=> {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode);
            reject(errorMessage);
          }).then(() => { 
            resolve();
          });
        });
      });
      return promise;
  }

  

  logOut(){
    this.afAuth.auth.signOut()
      .then(()=>{
        if (this.connectSource.getValue()==true){
          this.connectSource.next(false);
        }
          this.onAuthStateChangedSubscribe();
          this.zone.run(()=>
          this.router.navigate(['/home'])); 
          location.reload();
    })
  .catch((error)=> {
    // An error happened
  });
 
}



}
