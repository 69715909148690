import { NgModule } from "@angular/core";
import {RouterModule,Routes} from "@angular/router"
import { NewUserOrExistsComponent } from "./helper-components/new-user-or-exists/new-user-or-exists.component";
import { SignupByEmailComponent } from "./nvbar/signup/signup-by-email/signup-by-email.component";
import { LoginComponent } from "./nvbar/login/login.component";
import { HelpComponent } from "./help/help.component";
import { MultisiteComponent } from "./multisite/multisite.component";





const appRoutes: Routes = [
    {path: '',redirectTo: "/dashboard", pathMatch: "full"},
   //{path: '', component: AppComponent},
    {path:'dashboard',loadChildren: './dashboard/dashboard.module#DashboardModule'},
    {path:'calendar',loadChildren: './calendar/calendar.module#CalendarModule'},
    {path:'settings',loadChildren: './settings/settings/settings.module#SettingsModule'},
    {path:'adminConsole',loadChildren: './admin-console/admin-console.module#AdminConsoleModule'},
    {path:'qrScanner',loadChildren: './helper-components/qr-scanner/qr-scanner.module#QrScannerModule'},
     {path:'walletManager',loadChildren: './helper-components/wallet-manager/wallet-manager.module#WalletManagerModule'},
     {path:'promo/:id',loadChildren: './helper-components/promo-code/promo-code.module#PromoCodeModule'},
     {path:'promoManager',loadChildren: './promo-manager/promo-manager.module#PromoManagerModule'},
     {path: 'emd/:orgId/:employeeId',loadChildren: './schedule/employee-dashbord/employee-dashbord/employee-dashbord.module#EmployeeDashbordModule'},
     {path: 'loginOrSignup', component: NewUserOrExistsComponent},
     {path: 'signup', component: SignupByEmailComponent},
     {path: 'login', component: LoginComponent},
     {path: 'help', component: HelpComponent},
     {path: 'multisite', component: MultisiteComponent},

    // {path: 'dynamicTag', component: DynamicTagComponent},
    // {path: 'signupbyemail', component: SignupByEmailComponent},
    // {path: 'tagView', component: TagViewComponent},
    {path: 'home',loadChildren: './home/home.module#HomeModule'},
    {path: 'b4b',loadChildren: './bring4baby/bring4baby.module#Bring4BabyModule'},
    {path: 'tag/:tagId',loadChildren: './PublicTag/public-tag.module#PublicTagModule'},
    {path: 'tag/:tagId/:activity',loadChildren: './PublicTag/public-tag.module#PublicTagModule'},
    {path: 't/:tagId',loadChildren: './PublicTag/public-tag.module#PublicTagModule'},
    {path: 'f/:reportOrIssue/:orgId/:formId',loadChildren: './public-forms-view/public-forms-view.module#PublicFormsViewModule'},
    {path: 'not-found', loadChildren:'./page-not-found/page-not-found.module#PageNotFoundModule'},
   // {path: '**', redirectTo: '/not-found'}
    
  ];
  
  
@NgModule({
    imports : [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {

}