import { Injectable } from '@angular/core';

@Injectable()
export class WindowService {

  get windowRef(){
    return window
  }
  toStart(){
    this.windowRef.scroll(0,0)
  }
}
