import { Component, OnInit, ViewChild, NgZone, EventEmitter, Output, Input } from '@angular/core';
import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../services/modal.service';
import { SpinnerModelComponent } from '../../../modals/spinner-model/spinner-model.component';
import { SuccessModalComponent } from '../../../modals/success-modal/success-modal.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GetUserOrgIdService } from '../../../services/getUserOrgId.service';
import { ErrorModalComponent } from '../../../modals/error-modal/error-modal.component';
import { CookieService } from 'ngx-cookie-service';
import { DomainService } from '../../../services/domain.service';


@Component({
  selector: 'app-signup-by-email',
  templateUrl: './signup-by-email.component.html',
  styleUrls: ['./signup-by-email.component.css']
})
export class SignupByEmailComponent implements OnInit {
  signupForm: FormGroup;
  @ViewChild('errorModel',{static:false}) errorModel;
  @ViewChild('verifyYourEmailModel',{static:false}) verifyYourEmailModel;
  @ViewChild('promoCode',{static:false}) promotionCodeInput;
  @ViewChild('promoCodeButton',{static:false}) promoCodeButton;
  activeModal: NgbActiveModal;
  errorMessage:string;
  promoCode:string=null;


  constructor(
    private domainService:DomainService,
    private cookieService: CookieService,
    private userOrgId:GetUserOrgIdService,
    public deviceService: DeviceDetectorService,
    private location:Location,
    private authService: AuthService,
    private readonly afs: AngularFirestore,
    private modalGlobalService: ModalService,
    private modalService: NgbModal,
    private zone: NgZone,
    private router: Router) { }

  ngOnInit() {
    this.location.replaceState("");
    this.signupForm=new FormGroup({
      'firstname': new FormControl(null,Validators.required),
      'lastname': new FormControl(null,Validators.required),
      'email': new FormControl(null,[Validators.required,Validators.email]),
      'password': new FormControl(null,[Validators.required,Validators.minLength(6)]),
      'reenterpassword': new FormControl(null,[Validators.required,Validators.minLength(6)]),
      'recaptchaReactive': new FormControl(null, Validators.required)
    },this.CheckIfPasswordDontMatch.bind(this));
  }


  onSubmit(){
    this.modalGlobalService.init(SpinnerModelComponent, {},0);
    this.authService.doCreateUserWithEmailAndPassword(this.signupForm.value.email,this.signupForm.value.password,this.signupForm.value.firstname+' '+this.signupForm.value.lastname).then(()=>{
      this.authService.userStatus(true);
      this.getOrgId();
    }).catch((message:string)=>{
      this.modalGlobalService.destroy();
      this.errorMessage = message;
      this.activeModal=this.modalService.open(this.errorModel);
    })
  }


  goToDashboard(){
    this.zone.run(()=>
    {
      console.log("Go to Dashboard")
      this.router.navigate(['dashboard'])

    })
      ;
    
  }
 


  CheckIfPasswordDontMatch(control:FormControl): {[s:string]:boolean}{
    if (control.value.password != control.value.reenterpassword) {
      return {'passwordDontMatch':true}
    }

    return null
  }


  doGoogleLogin(){
    this.modalGlobalService.init(SpinnerModelComponent, {},0);
    this.authService.doGoogleLogin().then(()=>{
      this.authService.userStatus(true);
      this.getOrgId();
    }).catch((message:string)=>{
      this.modalGlobalService.destroy();
      this.errorMessage = message;
      this.activeModal=this.modalService.open(this.errorModel);

    })
  }

  finishWithSignUp(userOrgId:string){
    // if ( this.cookieService.check('promocode')) {
    //   this.afs.doc("organisationData/" +userOrgId+"/metaData/promoCode").set({"value":this.cookieService.get('promocode')})
    // }
    var languageCode;
    this.cookieService.get('Lan') ?  languageCode=this.cookieService.get('Lan') :  languageCode=navigator.language;
    // this.afs.doc("organisationData/" +userOrgId+"/metaData/metaDataFile").set({"languageCode":languageCode,"domain":this.domainService.getDomain()},{ merge: true });
    
    this.authService.checkVerificationEmail(true)
    .then(()=>{this.goToDashboard()})
    .catch(()=>{
      this.modalService.open(this.verifyYourEmailModel);
      this.goToDashboard();
});

  }

  getOrgId(){
    this.userOrgId.getUserOrgId().then((userOrgId:string)=> {
      this.modalGlobalService.destroy();
      this.modalGlobalService.init(SuccessModalComponent, {},1);
      this.finishWithSignUp(userOrgId);
    }).catch((error:string)=>{
      setTimeout(()=>{ this.getOrgId()}, 1500);
      console.log("signup run again in 1500 mil"+error);
     
   });}

  backClicked(){
    this.location.back();
  }

  checkPromoCode(promoCode:string){
    this.modalGlobalService.init(SpinnerModelComponent, {},0);
    this.afs.doc("promoCodes/"+promoCode).ref.get().then
    ((promoDoc) => {
      if (promoDoc.exists){
        this.modalGlobalService.destroy();
        this.modalGlobalService.init(SuccessModalComponent, {},0.5);
        this.promotionCodeInput.nativeElement.disabled = true; 
        this.promoCodeButton.nativeElement.disabled = true; 
        this.promoCode=promoCode;
      }

        })
        .catch((err)=>{
          console.log(err);
          this.modalGlobalService.destroy();
          this.modalGlobalService.init(ErrorModalComponent, {},0.5);
        })
    
             
    }
  
  
 

}
