import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {
  transform(birthdate: { year: number, month: number, day: number }): string {
    const today = new Date();
    const birthdateObj = new Date(birthdate.year, birthdate.month - 1, birthdate.day);
    let ageYears = today.getFullYear() - birthdateObj.getFullYear();
    let ageMonths = today.getMonth() - birthdateObj.getMonth();
    if (ageMonths < 0 || (ageMonths === 0 && today.getDate() < birthdateObj.getDate())) {
      ageYears--;
      ageMonths += 12;
    }
    return `${ageYears},${ageMonths}`;
  }
}