import { Component, AfterViewInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.css']
})
export class SuccessModalComponent implements AfterViewInit {

  constructor(private modalService: ModalService) { }

  ngAfterViewInit() {
   
  }
  
  public close() {
    this.modalService.destroy();
  }


}
