import { Component, OnInit } from '@angular/core';
import { GetUserOrgIdService } from '../services/getUserOrgId.service';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'global.dashboard',  icon: 'assets/images/dashboard-2.8s-333px.svg', class: '' },
  { path: 'dashboard/childrenManager', title: 'dashboard.children',  icon: 'assets/daycareIcons/baby.svg', class: '' },
  { path: 'dashboard/menu', title: 'dashboard.menu',  icon: 'assets/daycareIcons/chef-1s-200px.svg', class: '' },
  { path: 'dashboard/employees', title: 'dashboard.employees',  icon: 'assets/daycareIcons/employees.png', class: '' },
  { path: 'dashboard/reports', title: 'dashboard.reports',  icon: 'assets/daycareIcons/reports.png', class: '' },
  { path: 'calendar', title: 'dashboard.calendar',  icon: 'assets/daycareIcons/calendar.png', class: '' },
  { path: 'dashboard/shifts', title: 'dashboard.employeeSchedule',  icon: 'assets/daycareIcons/employeesShifts.png', class: '' },
  { path: 'dashboard/shifts/vacation', title: 'empVocations.empVocations',  icon: 'assets/daycareIcons/vacation.svg', class: '' },
  { path: 'dashboard/sickNotesEmp', title: 'dashboard.sickNotes',  icon: 'assets/daycareIcons/sickNote.svg', class: '' },
  { path: 'dashboard/messages', title: 'dashboard.messages',  icon: 'assets/daycareIcons/App-15.svg', class: '' },
  { path: 'dashboard/emergency', title: 'dashboard.emergency',  icon: 'assets/daycareIcons/emergency.svg', class: '' },
  { path: 'dashboard/settings', title: 'dashboard.settings',  icon: 'assets/daycareIcons/gear.svg', class: '' },

];

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})


export class SideMenuComponent implements OnInit {

  constructor(
    private userOrgId:GetUserOrgIdService
  ) { }
  menuItems: any[];

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.userOrgId.getUserMultisiteOrgIds().then(()=>{
      if (this.userOrgId.checkIfMultiSiteMoreThenOne()){
        this.menuItems.push({ path: 'multisite', title: 'global.switchOrg',  icon: 'assets/daycareIcons/relationship-2.1s-254px.png', class: '' })
  
      }
    })
   
   
  }


}
