import { PipeTransform, Pipe } from "@angular/core";
import {
 differenceInCalendarDays
} from 'date-fns';
@Pipe({
    name:'ArrayOfDatesTostringToDates'
})

export class ArrayOfDatesTostringToDates implements PipeTransform {
    //constructor (){}
    transform (array:Array<number>){
      let returnString=''
      if (!array || array.length===0) {
        return returnString
      }
      let index=0
      let start=null
      while (index <array.length) {
        var dateFormat = require('dateformat');
        var date = new Date(Number(array[index]));
        let dateString=dateFormat(date,"dd.mm").toString()
        
        if (array[index]) {
          if ((index<array.length-1) && differenceInCalendarDays(array[index+1],array[index])==1){
            start===null ? start=array[index] : null
          }
          if ((index<array.length-1) && !(differenceInCalendarDays(array[index+1],array[index])==1)){
            if (start==null){
              returnString!=''? returnString=returnString+','+dateString:returnString=returnString+dateString
              
            } 
            else{
              returnString=returnString+this.getDateString(start)+'-'+this.getDateString(array[index])+' ('+(differenceInCalendarDays(array[index],start)+1)+') '
              start=null
            }
           
          }
          if (index===array.length-1){
            returnString==='' ? null : returnString=returnString+','
            if (start===null){
              returnString=returnString+dateString 
            }
            else{
              returnString=returnString+this.getDateString(start)+'-'+this.getDateString(array[index])+' ('+(differenceInCalendarDays(array[index],start)+1)+') '
            }
          }
         
        }
        index++
      }
   
      return returnString
    }

    getDateString(date:number){
      var dateFormat = require('dateformat');
        return dateFormat(new Date(Number(date)),"dd.mm").toString()

    }
}
