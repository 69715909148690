import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateChild
  } from '@angular/router';
  import { Observable } from 'rxjs';
  import { Injectable } from '@angular/core';

import { BalanceService } from './balance.service';
  
  @Injectable()
  export class BalanceGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router,public balanceService:BalanceService) {}
  
    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    if ((this.balanceService.getValue())===null){
        return true;
    };
    if ((this.balanceService.getValue())<0.5){
        this.router.navigate(['/walletManager']);
        return false;
    };
    return true;
          }
  
    canActivateChild(route: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.canActivate(route, state);
    }
  }
  
  