import { PipeTransform, Pipe } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
@Pipe({
    name:'SafeResourceUrl'
})

export class SafeResourceUrl implements PipeTransform {
    constructor (private sanitizer: DomSanitizer){}
    transform (value:any){
      if (value) {
      return  this.sanitizer.bypassSecurityTrustResourceUrl(value.replace("watch?v=", "embed/"));}
    }
}