import { Component,EventEmitter, Output, OnInit, ViewChild, ElementRef, OnDestroy, NgZone} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../services/modal.service';
import { ChangeLan } from '../../services/changeLan.service';
import { SpinnerModelComponent } from '../../modals/spinner-model/spinner-model.component';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { faDollarSign,faUniversity,faQuestionCircle,faTachometerAlt,faUserPlus,faSignOutAlt,faLanguage,faWrench,faHandHoldingUsd,faTabletAlt} from '@fortawesome/free-solid-svg-icons';
import { BalanceService } from '../../services/balance.service';
import { GetUserOrgIdService } from '../../services/getUserOrgId.service';



@Component({
  selector: 'app-nvbar-manager',
  templateUrl: './nvbar-manager.component.html',
  styleUrls: ['./nvbar-manager.component.css']
})
export class UserManagerComponent implements OnInit,OnDestroy   {
  faHandHoldingUsd=faHandHoldingUsd;
  faDollarSign=faDollarSign;
  faWrench=faWrench;
  faLanguage=faLanguage;
  faSignOutAlt=faSignOutAlt;
  faTachometerAlt=faTachometerAlt;
  faUniversity=faUniversity
  faTabletAlt=faTabletAlt;
  faUserPlus=faUserPlus;
  faQuestionCircle=faQuestionCircle
  loginButton = false ;
  logOutButton = false;
  @ViewChild ('passwordStringInput',{static:false}) passwordInputRef: ElementRef; 
  @ViewChild ('emailStringInput',{static:false}) emailInputRef: ElementRef; 
  emailString: string = "";
  passwordString: string;
  closeResult: string = "";
  contant:any;
  message:string;
  @ViewChild('messageModel',{static:false}) messageModel;
  @ViewChild('showSignUp',{static:false}) showSignUp;
  @ViewChild('resetEmailModel',{static:false}) resetEmailModel;
  currentUserPositionSubscription:any;
  @Output() finishWithNavBar=new EventEmitter();
  activeModal: NgbActiveModal;
  imageStatus:string;
  verificationEmailSendAgainButton:boolean=false;
  balance:string=null;
  
  
  


   

  constructor(private changeLanSer: ChangeLan,
    private translate:TranslateService,
    public afAuth: AngularFireAuth,
    private modalGlobalService: ModalService,
    private modalService: NgbModal,
    private authService: AuthService,
    private balanceService: BalanceService,
    private router: Router,
    private zone: NgZone,
    private userOrgId:GetUserOrgIdService) {
   }

  changeLan(lan:string){
      this.changeLanSer.changeLan(lan);
      this.finishWithNavBar.emit();
   }
   

  logOut(){
    this.isLogged(false);
    this.authService.logOut();
    this.finishWithNavBar.emit();
  
}
goToDashBoard(){
  this.finishWithNavBar.emit();
  this.zone.run(()=>
  this.router.navigate(['dashboard']));
}

goToSttings(){
  this.finishWithNavBar.emit();
  this.zone.run(()=>
  this.router.navigate(['settings']));

}

showMultiButon(){
  return this.userOrgId.checkIfMultiSiteMoreThenOne()
}

checkBalance(){
  this.userOrgId.getUserOrgId().then((userOrgId:string)=> {
    this.balanceService.getBalance(userOrgId);
  }).catch(
    (error:string)=>{
      setTimeout(()=>{ this.checkBalance()}, 4000);
      console.log("nvbar run again in 2500 mil"+error);
  });
  this.balanceService.currentBalanceValue.subscribe(value => {
    value ? this.balance=(value.toFixed(2)):null;
    value===0 ? this.balance='0':null;
    this.balance ? null : this.balance='0';
    this.zone.run(() => {
    });

    }
);
}

goToWallet(){
  this.finishWithNavBar.emit();
  this.zone.run(()=>
  this.router.navigate(['walletManager']));
}
  
  

  ngOnInit(){

    this.currentUserPositionSubscription=this.authService.currentUserPosition.subscribe(status => {
     // status ? this.checkBalance():null;
      this.loginButton = !status;
      this.logOutButton = status;
    });

    this.modalGlobalService.init(SpinnerModelComponent, {},0);
    this.authService.isAuthenticated()
    .then(
      (status) => {
        this.modalGlobalService.destroy();
        if (status){
                  
                  this.authService.checkVerificationEmail(false).catch(()=>{
                    this.translate.get('user.verifyYourEmail').subscribe((message: string) => {
                      this.message=message;
                      this.imageStatus='warning';
                      this.modalService.open(this.messageModel);
                      this.verificationEmailSendAgainButton=true;
                    });

                  })
                
              
      }
        }
          );
    
   }

   ngOnDestroy(){
    //this.changeDetectorRef.detach();
    this.currentUserPositionSubscription.unsubscribe();
   }
    
  
   isLogged(logged:boolean) {
    logged ? this.checkBalance():null; 
    this.authService.userStatus(logged);
  
    
  }
  



  sendAgain(){
    this.authService.checkVerificationEmail(true);
  }

  goToLink(url: string){
    window.open(url, "_blank");
}

  



  


  
 

}
