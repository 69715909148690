import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GetUserOrgIdService } from './getUserOrgId.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { CookieService } from 'ngx-cookie-service';
import { DomainService } from './domain.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable(
  {
    providedIn: 'root',
  }
)
export class ChangeLan {
    private lanSource = new BehaviorSubject(null);
    currentLan = this.lanSource.asObservable();
    language='en';
    private rtl = new BehaviorSubject<boolean>(false);
    rtlStatus = this.rtl.asObservable();
    private childrenValue = new BehaviorSubject(null)
    currentChildrenValue = this.childrenValue.asObservable();
  
    constructor(
      
      private cookieService: CookieService,
      private readonly afs: AngularFirestore,
      private userOrgId:GetUserOrgIdService,
      private domainService:DomainService,
      private translate: TranslateService
    ) {
      this.lanFunction()
     }
  
    changeLan(lan: string) {
      this.lanSource.next(lan);
      this.language=lan;
      var expireDate = new Date(); 
      this.cookieService.set('Lan',lan,expireDate.getDate() + 365);
      // this.userOrgId.getUserOrgId().then((userOrgId:string)=> {
      //   this.afs.doc("organisationData/" +userOrgId+"/metaData/metaDataFile").set({"languageCode":lan},{ merge: true });
      // }).catch(()=>{});
      this.translate.use(this.domainService.getDomain()+lan);
      this.getRtl()
      
      
    }

    lanFunction(){
      this.language=this.getCurrentLan();
      this.translate.use(this.language);
    if (this.cookieService.check('Lan')){
      this.language=this.cookieService.get('Lan');
      this.translate.use(this.language);

    } 
    else{
      switch (navigator.language) {
        case 'he':
                this.language=navigator.language
                this.translate.use(this.language)
          
          break;
          case 'ru':
            this.language=navigator.language
            this.translate.use(this.language)
      
      break;
      
        default:
          break;
      }
    }  
    this.getRtl()

    }

    getCurrentLan(){
      return this.language;
      
    }

    getRtl(){
      
      switch (this.language) {
        case 'he':
              console.log("Return true")
              this.rtl.next(true)
        break;         

      
        default:
          console.log("Return flase")
          this.rtl.next(false);
          break
         
      }
    }
  
  }