import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { NvbarComponent } from "./nvbar.component";
import { UserManagerComponent } from "./nvbar-manager/nvbar-manager.component";
import { SignupByEmailComponent } from "./signup/signup-by-email/signup-by-email.component";
import { NvbarRoutingModule } from "./nvbar-routing.module";
import { LoginComponent } from './login/login.component';
import { SideMenuSharedModul } from "../side-menu/side-menu-shared";

@NgModule({
    declarations:[
        NvbarComponent,
        UserManagerComponent,
        SignupByEmailComponent,
        LoginComponent

    ],imports:[
        SharedModule,NvbarRoutingModule,SideMenuSharedModul
    ],
    exports:[
        NvbarComponent,
        UserManagerComponent,
        SignupByEmailComponent
    ]


})
export class NvbarModule {

}