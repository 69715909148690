import { Directive, Renderer2, OnInit, ElementRef, HostListener, HostBinding } from '@angular/core';

@Directive({
  selector: '[appMouseOver]'
})

export class mouseOverDirective implements OnInit {
  color:string;

  

  @HostListener('mouseenter') mouseOver(eventData:Event){
    this.color=getComputedStyle(this.elRef.nativeElement).borderColor;
    this.renderer.setStyle(this.elRef.nativeElement,'border-color','red');
    this.renderer.addClass(this.elRef.nativeElement,'bg-dark');
    
    
  }

  @HostListener('mouseleave') mouseleave(eventData:Event){
   this.renderer.setStyle(this.elRef.nativeElement,'border-color',this.color);
   this.renderer.removeClass(this.elRef.nativeElement,'bg-dark');
   
  }

  constructor(private elRef:ElementRef,private renderer: Renderer2) { }
  ngOnInit(){
    // this.renderer.setStyle(this.elRef.nativeElement,'border-color','blue');
  }

}
