import { Injectable } from '@angular/core';
//import domtoimage from 'dom-to-image';
var domtoimage = require('dom-to-image-more');



@Injectable()
export class PrintService {
  
    constructor(
    ) { }
  
  
    print(printId:string='print-section'){
      var printContents = document.getElementById(printId).innerHTML;
      var stylesHtml = this.getTagsHtml('style');
      var linksHtml = this.getTagsHtml('link');
      
      var popupWin = window.open('', '_blank', 'width=300,height=300');
      popupWin.document.open();
      popupWin.document.write(`
          <html>
              <head>
              <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
                  ${linksHtml}
                  ${stylesHtml}
                  <style>
                    @page { size: auto;  margin: 10mm; }
                  </style>
              </head>
              <body onload="setTimeout(function(){window.print()}, 1500)">
                   <div class="col" style="height:50px;"></div>   
                  ${printContents}
              </body>
          </html>
          `
      );
      
      popupWin.document.close(); // necessary for IE >= 10
      popupWin.focus(); // necessary for IE >= 10*/
      
      setTimeout(()=>{popupWin.close();}, 4000);
      
      }
      private getTagsHtml(tagName: keyof HTMLElementTagNameMap): string
      {
          const htmlStr: string[] = [];
          const elements = document.getElementsByTagName(tagName);
          for (let idx = 0; idx < elements.length; idx++)
          {
              htmlStr.push(elements[idx].outerHTML);
          }
      
          return htmlStr.join('\r\n');
      }

      htmlToImage(elementId:string){
        var clone = document.getElementById(elementId);
        var width=clone.style.width;
        clone.setAttribute("style", "width: 595px");
        //document.body.appendChild(clone);
        const promise=  new Promise<any>((resolve, reject) => {
        //domtoimage.toJpeg(document.getElementById(elementId), {bgcolor:'white'})
        domtoimage.toJpeg(clone, {bgcolor:'white'})
        .then(function (dataUrl) {
            //clone.remove();
            clone.setAttribute("style", "width:wigth");
            resolve(dataUrl);
        
        }).catch((err)=>{
            clone.setAttribute("style", "width:wigth");
            reject(err);});
        

    });
        return promise;
    }

  }