import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-new-user-or-exists',
  templateUrl: './new-user-or-exists.component.html',
  styleUrls: ['./new-user-or-exists.component.css']
})
export class NewUserOrExistsComponent implements OnInit {

  constructor(private location:Location,
    public deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.location.replaceState("");
  }

  backClicked(){
    this.location.back();
  }

}
