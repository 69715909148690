import { Component, Output, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavbarService } from '../services/navBar.service';
import { DomainService } from '../services/domain.service';



@Component({
  selector: 'app-nvbar',
  templateUrl: './nvbar.component.html',
  styleUrls: ['./nvbar.component.css']
})
export class NvbarComponent {
  
  @Output() userLogined = new EventEmitter<boolean>();
  @Output() tagManageButtonPushed = new EventEmitter();
  closeResult: string = "";
  userLoginedValue:boolean = false;
  navbarOpen = false;
  timer:any

 

  constructor(public afAuth: AngularFireAuth,public navbarSer: NavbarService,public domSer:DomainService ) {
   
  }
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
    this.navbarOpen?this.setTimer():null;
  }
  closeNavBar(){
    this.navbarOpen=false;
  }
  
 setTimer() {
    clearTimeout(this.timer );
    this.timer = setTimeout(()=>{ this.navbarOpen=false}, 5000);
}

  



}
