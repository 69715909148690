import { NgModule } from "@angular/core";
import { StringToDateDay } from "./StringToDateDay.pipe";



@NgModule({
    declarations: [StringToDateDay],
    exports: [StringToDateDay],
    imports:[]
})
export class StringToDateDaySharedModul {}