import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { StringToDate } from "../app/pipes/stringToDate.pipe";
import { SafeResourceUrl } from "../app/pipes/safeResourceUrl.pipe";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { QRCodeModule } from 'angularx-qrcode';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { DateParserFormatter } from "../app/services/date-parser-formater";
import { StringToDateHoursOnly } from "../app/pipes/stringToDateHoursOnly.pipe.";
import { StringToDateDay } from "../app/pipes/StringToDateDay.pipe";
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha'
import { StringToDateDaySharedModul } from "../app/pipes/StringToDateDay-shared-module";
import { StringToDateHoursOnlySharedModul } from "../app/pipes/StringToDateHoursOnly-shared-module";
import { AgePipe } from "src/app/pipes/age.pipe";





@NgModule({
    declarations: [
        StringToDate,
        SafeResourceUrl,
        AgePipe
    ]
,imports:[
    NgbModule,
    Ng2TelInputModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    TranslateModule,
    QRCodeModule,
    StringToDateDaySharedModul,
    StringToDateHoursOnlySharedModul,
    FontAwesomeModule],
    providers:[{ provide: NgbDateParserFormatter, useClass: DateParserFormatter }],

    entryComponents:[],    

    exports: [
        Ng2TelInputModule,
        SafeResourceUrl,
        AgePipe,
        QRCodeModule,
        FontAwesomeModule,
        FormsModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        ReactiveFormsModule,
        NgbModule,
        CommonModule,
        StringToDate,
        StringToDateHoursOnly,
        TranslateModule
        
    ]
})
export class SharedModule {}