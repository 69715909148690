import { Component, OnInit, NgZone } from '@angular/core';
import { GetUserOrgIdService } from '../services/getUserOrgId.service';
import { ModalService } from '../services/modal.service';
import { SpinnerModelComponent } from '../modals/spinner-model/spinner-model.component';
import { MetaDataService } from '../services/meta-data.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-multisite',
  templateUrl: './multisite.component.html',
  styleUrls: ['./multisite.component.css']
})
export class MultisiteComponent implements OnInit {

  constructor(private guid:GetUserOrgIdService,
    private readonly afs: AngularFirestore, 
    private zone: NgZone,
     private modalGlobalService:ModalService,private metadataSer:MetaDataService) { }
  orgArr=[]
  metaDataDoc={}
  mapChildrenByOrgId={}
  async ngOnInit() {
      this.getUserOrgIds()
  }

   async getUserOrgIds(){
    this.modalGlobalService.destroy()
    this.modalGlobalService.init(SpinnerModelComponent, {},0);
    var promises=[]
    try {
      this.orgArr=await this.guid.getUserMultisiteOrgIds()
      this.modalGlobalService.destroy()
   
    } catch (error) {
      console.log(error)
      
    }
    if (this.orgArr.length==1){
      console.log("this.orgArr.length:"+this.orgArr.length)
      this.setOrgId(this.orgArr[0])
      this.modalGlobalService.init(SpinnerModelComponent, {},0);
      return
    }
    for (const userOrgId of this.orgArr) {
      promises.push(this.getMetaData(userOrgId))
      promises.push(this.getAllChildren(userOrgId))
      
    }
    this.modalGlobalService.init(SpinnerModelComponent, {},0);
    Promise.all(promises).then(()=>{
      this.modalGlobalService.destroy()
      this.zone.run(() => {});
      
    
    })

  }


  getMetaData(userOrgId) {
       
    const promise=   new Promise<any>((resolve, reject) => {
        this.afs.doc("dayCareData/"+userOrgId+"/settings/metaDataFile").ref.onSnapshot
        ((doc) => {
                    if (doc.exists) {
                        this.metaDataDoc[userOrgId]=doc.data()
                        resolve(true)
                 
                    }
                  
            
                        
                }
                ,err  => {
                    reject(err);
                })
   
})


return promise;}

getAllChildren(orgId){
  const promise=   new Promise<any>((resolve, reject) => {
              var collection =  this.afs.collection("dayCareData/" + orgId +"/children").ref.where('status','==',true)
              .onSnapshot
              ((snapshot) => {
              snapshot.docs.forEach((doc, idx, array) => {
                      if (this.mapChildrenByOrgId[orgId] === undefined) {
                        this.mapChildrenByOrgId[orgId] = []
                      }
                      this.mapChildrenByOrgId[orgId].push(doc.data())
              });
              resolve(true);
          }

              ,err  => {
              reject(err);
              console.log(err);
              })
          return promise
  
})}

  setOrgId(orgId:string){
    if (orgId!=this.guid.userOrgId){
      this.metadataSer.resetAll()
     
      
    }
    this.guid.setOrgId(orgId)

  }

}
