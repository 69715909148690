import { Injectable, NgZone } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";

@Injectable()
export class BalanceService {
  
    constructor(private readonly afs: AngularFirestore,
        private router: Router,
        private zone: NgZone,
         ) { }
        private balanceValue = new BehaviorSubject(null)
        currentBalanceValue = this.balanceValue.asObservable();
        value=null;

    getBalance(userOrgId:string){
        this.afs.collection("organisationData/" + userOrgId +"/metaData").doc("balance").ref.onSnapshot
        ((balance) => {
         
            if (balance.data()) {
                this.balanceValue.next(balance.data().value);
                this.value = balance.data().value;
                // if ((this.value)<1) {
                //     this.goToWallet();
                // }
            }
        }
        ,err  => {
          console.log(err);
        })
    
     
    
    }

    goToWallet(){
        this.zone.run(()=>
        this.router.navigate(['walletManager']));
      }

      getValue(){
          return this.value;
      }
}