import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';



@Injectable()
export class ImageService {
  
    constructor(private storage: AngularFireStorage) { }
    
    
    checkTheFile(file:File){
        const fileReader = new FileReader();
      
        const promise=  new Promise<any>((resolve, reject) => {
          fileReader.addEventListener("loadend", () => {
            const arr = new Uint8Array(<ArrayBuffer>fileReader.result).subarray(0, 4);
            let header = "";
            let isValid = false;
            for (let i = 0; i < arr.length; i++) {
              header += arr[i].toString(16);
            }
            console.log("heder"+header)
            switch (header) {
              case "89504e47":
                isValid = true;
                break;
              case "3c3f786d": //svg  
              case "ffd8ffe0":
              case "ffd8ffe1":
              case "ffd8ffe2":
              case "ffd8ffe3":
              case "ffd8ffe8":
                isValid = true;
                break;
              default:
                isValid = false; // Or you can use the blob.type as fallback
                break;
            }
            if (isValid) {
              resolve();
            } else {
              reject("invalidMimeType")
            }
          });
          fileReader.readAsArrayBuffer(file);
        });
      return promise; 
      
      }

      checkTheFilePngOnly(file:File){
        const fileReader = new FileReader();
      
        const promise=  new Promise<any>((resolve, reject) => {
          fileReader.addEventListener("loadend", () => {
            const arr = new Uint8Array(<ArrayBuffer>fileReader.result).subarray(0, 4);
            let header = "";
            let isValid = false;
            for (let i = 0; i < arr.length; i++) {
              header += arr[i].toString(16);
            }
           // console.log("heder"+header)
            switch (header) {
              case "89504e47":
                isValid = true;
                break;
              // case "3c3f786d": //svg  
              // case "ffd8ffe0":
              // case "ffd8ffe1":
              // case "ffd8ffe2":
              // case "ffd8ffe3":
              // case "ffd8ffe8":
              //   isValid = true;
              //   break;
              default:
                isValid = false; // Or you can use the blob.type as fallback
                break;
            }
            if (isValid) {
              resolve();
            } else {
              reject("invalidMimeType")
            }
          });
          fileReader.readAsArrayBuffer(file);
        });
      return promise; 
      
      }
      

      calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {

        var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
      
        return { width: srcWidth*ratio, height: srcHeight*ratio };
      }

      resizeImage(img: HTMLImageElement,maxWidth:number,maxHeight:number,returnFileName:string){
        const promise=  new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            const elem = document.createElement('canvas');
              console.log(img.width,img.height);
              var newSize = this.calculateAspectRatioFit(img.width,img.height,maxWidth,maxHeight)
              const ctx = elem.getContext('2d');
              if (img.width>maxWidth && img.height>maxHeight){
                elem.width = newSize.width;
                elem.height = newSize.height;  
                ctx.drawImage(img, 0, 0, newSize.width, newSize.height);
              }
              else{
                elem.width = img.width;
                elem.height = img.height;
                ctx.drawImage(img, 0, 0, img.width, img.height);
              }
              console.log(elem.width,elem.height);
              
              ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], returnFileName, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  reader.onload = () => {
                    resolve(file);

                  };
                  reader.readAsDataURL(file);
                  reader.onerror = error => reject(error);
                }, 'image/jpeg', 1);

        });
        return promise;

      }
      uploadImageBase64(file:string,path:string,fileName:string){
        var metadata = {
          cacheControl: 'public,max-age=31536000'
        };
        var promise=new Promise((resolve, reject) => {;
            var upload=this.storage.ref(path+fileName).putString(file,'data_url',metadata);
            upload.then((snapshot)=>{
              snapshot.ref.getDownloadURL().then((url)=>{
              resolve(url) });
          }).catch((error)=>{
            reject(error);
          })
   
    
      });
      return promise;
    }
      

    
  
    
  }