import { Component, OnInit, Input, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { SpinnerModelComponent } from '../../modals/spinner-model/spinner-model.component';
import { AuthService } from '../../services/auth.service';
import { SuccessModalComponent } from '../../modals/success-modal/success-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ErrorModalComponent } from '../../modals/error-modal/error-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  ngOnInit() {
    this.location.replaceState("");
  }
  
  @ViewChild('email',{static:false}) emailRef: ElementRef; 
  @ViewChild('password',{static:false}) passwordRef: ElementRef;
  @ViewChild('messageModel',{static:false}) messageModel;
  @ViewChild('resetEmailModel',{static:false}) resetEmailModel;
  message:string;
  imageStatus:string;
  constructor(
    public deviceService: DeviceDetectorService,
    private location:Location,
    private zone: NgZone,
    private router: Router,
    public afAuth: AngularFireAuth,
    private modalGlobalService: ModalService,
    private authService: AuthService,
    private translate:TranslateService,
    private modalService: NgbModal,
  ) {}

  isLogged(logged:boolean) {
    //logged ? this.checkBalance():null; 
    this.authService.userStatus(logged)
    this.goToDashboard();}

    goToDashboard(){
      this.zone.run(()=>
        this.router.navigate(['dashboard']));
      
    }
  
 
   doGoogleLogin(){
    this.modalGlobalService.init(SpinnerModelComponent, {},0);
    this.authService.doGoogleLogin().then(()=>{
      this.modalGlobalService.destroy();
      this.modalGlobalService.init(SuccessModalComponent, {},0.5);
      this.isLogged(true);
    }).catch((message:string)=>{
      this.modalGlobalService.destroy();
      this.modalGlobalService.init(ErrorModalComponent, {},1);
      this.message=message;
      this.imageStatus='negative';
      this.modalService.open(this.messageModel);

    })
  }

  loginWithUserPassword(){
    this.modalGlobalService.init(SpinnerModelComponent, {},0);
    this.authService.loginWithUserPassword(this.emailRef.nativeElement.value, this.passwordRef.nativeElement.value).then(()=>{
      this.modalGlobalService.destroy();
      this.modalGlobalService.init(SuccessModalComponent, {},0.5);
      this.isLogged(true);
      this.authService.checkVerificationEmail(false).catch(()=>{
        this.translate.get('user.verifyYourEmail').subscribe((message: string) => {
          this.message=message;
          this.imageStatus='warning';
          this.modalService.open(this.messageModel);
        });

      })

    }).catch((message:string)=>{
      this.modalGlobalService.destroy();
      this.modalGlobalService.init(ErrorModalComponent, {},1);
      this.imageStatus='negative';
      this.message=message;
      this.modalService.open(this.messageModel);
    })
   
  }

  resetPasswordModal(){
    this.modalService.open(this.resetEmailModel, {ariaLabelledBy: 'modal-basic-title', size:'sm',centered: true  });  
  }

  resetPassword(email:string){
    this.modalGlobalService.init(SpinnerModelComponent, {},0);

      this.afAuth.auth.sendPasswordResetEmail(email).then(() => {
        this.modalGlobalService.destroy();
        this.translate.get('user.checkEmail').subscribe((message: string) => {
          this.message=message;
          this.imageStatus='positive';
          this.modalService.open(this.messageModel);
        });

      }).catch((error)=> {
        this.modalGlobalService.destroy();
        this.message=error;
        this.imageStatus='negative';
        this.modalService.open(this.messageModel);
      
      });

  }


  backClicked(){
    this.location.back();
  }



}
