import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-network-status',
  templateUrl: './network-status.component.html',
  styleUrls: ['./network-status.component.css']
})
export class NetworkStatusComponent implements OnInit {

    isConnected:boolean = navigator.onLine;

  constructor(public deviceService: DeviceDetectorService)  {
      window.addEventListener('online', () => {this.isConnected = true});
      window.addEventListener('offline', () => {this.isConnected = false});
    // this.connectionService.monitor().subscribe(isConnected => {
    //   this.isConnected = isConnected;
    // })
  }

  ngOnInit() {
  }

}
